"use client";

import ErrorPageView from "pages-sections/error-pages/ErroPageView";

// ==============================================================
interface Props {
	error: Error & { digest?: string };
	reset: () => void;
}
// ==============================================================

export default function Error({
	error,
	reset,
}: Props) {
	return (
		<ErrorPageView
			error={error}
			reset={reset}
		/>
	);
}
