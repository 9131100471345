import { Open_Sans } from "next/font/google";

export const themeFont = Open_Sans({
	subsets: ["latin"],
});

export const fontSize = 14;

export const typography = {
	fontSize,
	htmlFontSize: 14,
	fontFamily:
		themeFont.style.fontFamily,
	body1: { fontSize },
	body2: { fontSize },
};
