import Card from "@mui/material/Card";
// GLOBAL CUSTOM COMPONENTS
import BazaarCTA from "components/BazaarCta";
import { H1 } from "components/Typography";
import { FlexRowCenter } from "components/flex-box";
import handleError from "utils/handleError";

// ==============================================================
interface Props {
	error: Error & { digest?: string };
	reset: () => void;
}
// ==============================================================

export default function ErrorPageView({
	error,
	reset,
}: Props) {
	// 	handle error
	handleError({
		error,
		errorMessage: error.message,
		logError: true,
		notifyError: false,
	});

	return (
		<FlexRowCenter
			my={6}
			px={2}
			flexDirection='column'>
			<Card
				sx={{
					p: 4,
					textAlign: "center",
				}}>
				<H1 my={4}>
					Ooops, Quelque Chose
					s&apos;est mal passée!
				</H1>

				<BazaarCTA
					color='primary'
					variant='contained'
					sx={{ my: 2 }}
					onClick={() => reset()}>
					Reéssayer
				</BazaarCTA>
			</Card>
		</FlexRowCenter>
	);
}
